import React from "react"
import SEO from "../components/SEO"
import FAQComponent from "../components/FAQs"
import FamilySuggestions from "../components/FamilySuggestions"

import styles from "../styles/modules/static-pages.module.scss"

const questions = [
  {
    question: <>What is NeighborShare?</>,
    answer: (
      <>
        NeighborShare is a nonprofit tech platform that enables donors to
        quickly and easily help a household in need within an ecosystem of
        trust. Our vision is to increase individual economic resiliency while
        strengthening the fabric of connection between neighbors by creating the
        ability to literally ‘help a neighbor.’
        <br></br>
        <br></br>
        We partner with reputable, vetted 501(c)(3) community organizations to
        identify and validate stories of need that would otherwise go unmet,
        providing a new path when other options have run out. Many people
        supported by NeighborShare and our partners are unable to make ends meet
        and risk losing their jobs or homes due to emergent but relatively small
        financial needs, like paying for childcare, car repairs, or even gas. In
        other cases, individuals are trapped in a cycle of unemployment because
        of barriers to childcare, transportation, or equipment.
      </>
    ),
  },
  {
    question: <>How does NeighborShare work?</>,
    answer: (
      <div className={styles.listContainer}>
        <ol>
          <li>
            NeighborShare partners with vetted direct-service nonprofit
            organizations like shelters, youth programs, domestic violence
            support organizations, etc.
          </li>
          <li>
            Our partner nonprofits are working directly on the ground in local
            communities and are able to source validated moments of pivotal
            financial need that are otherwise going unmet for vulnerable
            households. Partners are able to quickly and easily upload those
            needs to the NeighborShare platform.
          </li>
          <li>Donors select household need(s) to support.</li>
          <li>
            Donations go directly to the sponsoring nonprofit and will be used
            to support a household in need. The sponsoring nonprofit hopes to
            help the household you select, but funds cannot be formally
            earmarked in order to protect the tax treatment of your donation. In
            certain cases, the nonprofit may choose to direct the funds to a
            similar household at their discretion (for example, if the initial
            household’s needs are already filled).
          </li>
        </ol>
      </div>
    ),
  },
  {
    question: <>How do I help?</>,
    answer: (
      <>
        As a donor, you can give directly to a specific household using the{" "}
        <a href="https://nbshare.org/#explore-needs/">Browse Needs page</a> of
        our website. You can also contribute to the general NeighborShare Fund
        by clicking Donate at the top of the website. (Become a SUPERSTAR
        neighbor by making your donation recurring!) We distribute funds from
        the NeighborShare Fund to families in need on your behalf, typically
        prioritizing the needs that have been on our platform for the longest
        and are still unfulfilled.
        <br></br>
        <br></br>
        If you are an institutional donor or want to explore a larger
        partnership, please contact us at{" "}
        <a href={"mailto:info@nbshare.org"}>info@nbshare.org!</a>
      </>
    ),
  },
  {
    question: (
      <>How does NeighborShare select and validate partner organizations?</>
    ),
    answer: (
      <>
        NeighborShare’s partner organizations are selected based on a set of
        vetting criteria including mission alignment, strength of their
        reputation and reach, and operational capability. Most are rated Silver
        or better by Guidestar’s Seal of Transparency rating system.
      </>
    ),
  },
  {
    question: <>How much of my donation goes directly to recipients?</>,
    answer: (
      <>
        Stripe, the payment processor used on this site, charges 2.2% + $0.30
        per transaction. Some donors opt to cover this fee. 100% of the rest of
        your donation made via the platform goes to the partner nonprofit to
        support households in need.
      </>
    ),
  },
  {
    question: <>How are funds distributed to recipients?</>,
    answer: (
      <>
        Each partner nonprofit decides how to distribute the funds based on
        their understanding of how best to serve their communities. Many
        NeighborShare partners pay a bill directly, some take their client
        shopping, and others issue direct cash in the form of a check or cash
        app transfer. NeighborShare’s model enables each nonprofit partner to
        disseminate the assistance in the way that makes the most sense for its
        program and the individual client.
      </>
    ),
  },
  {
    question: (
      <>I’m struggling and could use a hand. Could NeighborShare help me?</>
    ),
    answer: (
      <>
        NeighborShare’s current model uses trusted nonprofits to validate and
        source the needs on our site. If you are currently working with a
        nonprofit or other community organization, please tell them about us so
        we can explore partnership. We also have a{" "}
        <a href="https://nbshare.org/resources/">
          growing list of national, individualized resources
        </a>{" "}
        that might be helpful to you.
      </>
    ),
  },
  {
    question: (
      <>
        I’m a non-profit, caseworker, teacher, clergyperson, or other trusted
        community member, and want to use NeighborShare. How do I get started?
      </>
    ),
    answer: (
      <>
        At this time, we are not adding more partners to the platform. But
        please email us at{" "}
        <a href={"mailto:info@nbshare.org"}>info@nbshare.org</a> to be added to
        our waitlist so we can let you know when we do open up more partner
        slots!
      </>
    ),
  },
  {
    question: <>Do direct cash transfer programs really work?</>,
    answer: (
      <>
        Yes! Research by organizations such as{" "}
        <a
          href="https://www.poverty-action.org/impact/cash-transfers-changing-debate-giving-cash-poor"
          target="_blank"
          rel="noopener noreferrer"
        >
          Innovations for Poverty Action
        </a>{" "}
        and the{" "}
        <a
          href="https://www.worldbank.org/en/research/dime/brief/Cash-Transfers-and-Temptation-Goods"
          target="_blank"
          rel="noopener noreferrer"
        >
          World Bank
        </a>{" "}
        have concluded that households in need, like all households, know best
        how to prioritize the needs of their own family. In fact, several
        studies have shown that providing cash assistance increases peoples’
        ability to obtain and retain jobs, housing, healthcare, and improves
        their mental wellbeing and self-efficacy.
      </>
    ),
  },
  {
    question: <>How do I change or cancel my recurring donation?</>,
    answer: (
      <>
        Please go to{" "}
        <a href={"https://nbshare.org/manage-subscription"}>My Account</a> and
        follow the instructions there.
      </>
    ),
  },
  {
    question: <>Is my contribution tax-deductible?</>,
    answer: (
      <>
        <p>Yes, donations are tax-deductible to the extent permitted by law.</p>
        <p>
          Donors receive a donor acknowledgement letter from NeighborShare. Our
          federal EIN is 85-0811667.
        </p>
      </>
    ),
  },
  {
    question: <>When and why was NeighborShare founded?</>,
    answer: (
      <>
        NeighborShare was founded during the COVID-19 pandemic, in 2020, by two
        people who wished they could lend a hand to a neighbor in need, but
        didn’t know how to find the folks who most needed their help. After
        researching the social services ecosystem and other donation sites, they
        decided to launch NeighborShare. Built with both donors and nonprofits
        in mind, NeighborShare is a platform designed to quickly connect people
        who can help to those who need help, reducing the time and red tape it
        usually takes someone to get financial assistance. Today, NeighborShare
        is powered by a small team and a large community of amazing volunteers
        dedicated to scaling NeighborShare to every community.
      </>
    ),
  },
  {
    question: <>I have a question not listed here.</>,
    answer: (
      <>
        Please email us at{" "}
        <a href={"mailto:info@nbshare.org"}>info@nbshare.org</a>
      </>
    ),
  },
]

const FAQs = () => (
  <>
    <SEO title="FAQs" />
    <FAQComponent questions={questions} />
    <FamilySuggestions title="Meet households in need" />
  </>
)

export default FAQs
